#stayheader {
    min-height: 60px;
    display: flex;
    /* position: fixed; */
    background: #222;
    color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    z-index: 1000;
}
#stayheader .bu {
    font-weight: normal;
}
.total-guest-count {
    font-size: 0.85em;
}
.tab-content-color{
    background-color: #ededed;
}
.edit-stay {
    font-size: 0.85em;
    display: flex;
    background-color: rgba(32, 33, 36, 0.059);
    color: #fff;
    text-decoration: none;
    box-shadow: inset 0 0 0 1px #dadce0;
    padding: 10px 15px;
    border-radius: 4px;
    font-weight: 500;
}
.edit-stay:hover {
    background-color: rgba(32, 33, 36, 0.1);
}
.staydate {
    padding: 0 0 5px;
    font-size: 0.85em;
}
.staydate strong {
    font-weight: 600;
}
.select-rooms {
    display: flex;
    min-height: 100vh;
    max-height: 100vh;
    padding-top: 60px;
}
.rooms-list {
    width: calc(100% - 400px);
    background: #f5f5f5;
    overflow: auto;
    border-right: 1px solid #ccc;
}
.reservation-summery {
    width: 400px;
    background: #fff;
    overflow: auto;
}
.reservation-summery h2 {
    font-size: 1em;
    padding: 20px 20px 0;
    font-weight: 500;
    color: #666;
    display: flex;
    justify-content: space-between;
}
.reservation-summery h2 strong {
    color: #000;
}
#summeryRooms dl {
    margin: 20px 20px 0;
    padding: 10px 10px 5px;
    border: 1px solid #dadce0;
    border-radius: 2px;
    background: #fafafa;
}
#summeryRooms dt {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
#summeryRooms dt strong {
    font-size: 0.85em;
}
#summeryRooms dd {
    font-size: 0.85em;
    padding: 5px 0;
    margin: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 30px;
    align-items: center;
    min-height: 40px;
}

#summeryRooms dd strong {
    font-weight: normal;
    font-size: 12px;
}
#summeryRooms .pax-package {
    font-size: 0.85em;
}
#summeryRooms dd a {
    color: var(--main-button-color);
    text-decoration: underline;
    cursor: pointer;
}
#summeryRooms dd a:hover {
    text-decoration: none;
}
#summeryRooms dl.active {
    border: 1px solid var(--main-button-color);
    background: #fff;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
}
#summeryRooms dl.selected {
    border-color: #33b679;
}
.roomlist-header {
    padding: 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.roomlist-header em {
    font-style: normal;
    font-size: 0.8em;
}
.roomlist-header a.bu {
    font-size: 0.8em;
    border: 1px solid rgba(0, 0, 0, 0.08);
    background: none;
    color: #222;
}
.room-status {
    padding: 0 20px;
    font-weight: 500;
    font-size: 0.85em;
}

.avilable-rooms {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}
.avilable-rooms li {
    margin: 10px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
    background: #fff;
    padding: 10px;
    width: calc(33.333% - 20px);
    font-size: 0.85em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.avilable-rooms li img {
    width: 100%;
}
.room-disc {
    padding: 10px;
    margin-bottom: auto;
}
.room-disc h3 {
    display: flex;
    justify-content: space-between;
}
.image-holder {
    max-height: 170px;
    overflow: hidden;
}
.select-rrom-button-holder {
    border: 1px solid var(--main-button-color);
    display: block;
    padding: 10px;
    text-align: center;
    font-weight: 600;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.selectRoomButton {
    color: var(--main-button-color);
}
.select-rrom-button-holder:hover {
    background: #f5f5f5;
}
.select-rrom-button-holder.active {
    background: #33b679;
    color: #fff;
    border-color: #33b679;
}
.select-rrom-button-holder.active .selectRoomButton {
    color: #fff;
}
.room-disc p {
    margin: 0;
    padding: 0;
    font-size: 0.8em;
    line-height: 1.4em;
    color: #999;
}
.avilable-rooms em {
    font-size: 0.8em;
    display: block;
    padding: 5px 5px 0;
    font-style: normal;
    text-align: center;
    color: #999;
}
.selected-room-name {
    color: #00924e;
    padding: 0;

    font-size: 0.85em;
    font-weight: 600;
    border-radius: 3px;
}
.priceTags {
    text-align: right;
    line-height: 1.8em;
    font-size: 0.85em;
    color: #666;
}
#summeryRooms dd .priceTags strong {
    font-size: 1.1em;
    font-weight: bold;
}
.total-on-room {
    font-size: 13px;
    text-align: right;
}
.total-price-room strong {
    text-decoration: underline;
    cursor: pointer;
}

.total-price-room strong:hover {
    text-decoration: none;
}
#summeryRooms dt .total-on-room strong {
    font-size: 14px;
    color: #00924e;
}

.final-bill {
    text-align: right;
    padding: 10px 20px;
    position: sticky;
    bottom: 0;
    background: rgba(100%, 100%, 100%, 0.8);
    margin-top: 20px;
}
.final-pay {
    font-size: 2em;
}
.sort-room {
    display: flex;
    align-items: center;
}
.sort-room label {
    white-space: nowrap;
    font-size: 0.8em;
    padding-right: 5px;
}

.minimum-price .priceTags,
.minimum-price .total-surcharge-on-room {
    display: none;
}
.minimum-price dd div:first-child {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.proceedtobook {
    padding: 10px 0 20px;
}
.edit-price {
    display: flex;
    /* min-height: 100vh;
    max-height: 100vh; */
    /* padding-top: 60px; */
}
.edit-price > div:first-child {
    width: 100%;
}
.edit-price.showprice-details {
    display: flex;
}
.booked-rooms {
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 20px 20px 30px;
}
.booked-rooms li {
    font-size: 2em;
}
.booked-rooms h2 {
    font-size: 0.9em;
    padding: 0 0 10px;
}
.booked-rooms .table {
    margin-bottom: 10px;
    font-size: 0.4em;
}
.room-price-heading {
    display: flex;
    height: 50px;
    border-bottom: 1px solid #dedede;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background: #fafafa;
}
.back-to-room-edit a {
    display: flex;
    align-items: center;
    font-size: 0.8em;
    line-height: 1;
    cursor: pointer;
}
.roomaddons {
    font-size: 0.85em;
    padding-bottom: 20px;
}
.roomAddonslist {
    display: none;
    font-size: 0.5em;
}
.on > .roomAddonslist {
    display: block;
}
.on > .room-addons-link {
    display: none;
}
.addon-selector-box {
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
}
.addon-selector-box:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 2px 6px 2px rgba(60, 64, 67, 0.149);
}
.addon-popup-footer {
    padding: 0 20px 20px;
    display: flex;
    justify-content: space-between;
}
.addons-options dt {
    padding-bottom: 10px;
    font-weight: 600;
    color: var(--main-button-color);
    display: none;
}
.addons-options dl {
    margin: 0;
    padding: 20px 0 0 20px;
    flex-wrap: wrap;
}
.addons-options dt {
    width: 100%;
}
.addons-options dd {
    margin: 0 20px 20px 0;
    padding: 10px 10px 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: calc(25% - 20px);
    border-radius: 5px;
    background: #fff;
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column;
}
.addons-options-footer {
    display: flex;
    margin-top: auto;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
}
.addons-count-selected {
    font-size: 0.85em;
    white-space: nowrap;
}
.addon-box-details p {
    margin: 0;
    padding: 5px 0 0;
    font-size: 0.85em;
}
.addons-count-price {
    font-weight: 600;
}
.addons-options .special-reqest dd {
    border: none;
    padding: 0 10px 10px 0;
    margin: 0;
    background: none;
    min-height: 0;
    flex-direction: row;
    width: 100%;
}
.addons-options .special-reqest .addon-box-details {
    width: 100%;
}
.addons-options .special-reqest .addons-count-selected {
    padding-right: 10px;
}
.addons-options .special-reqest dd > * {
    padding-right: 10px;
}
.addons-options .special-reqest dd > .form-actions {
    display: none;
}
.addons-options .special-reqest .addons-options-footer {
    padding-top: 0;
}
.addons-options .special-reqest .select-check {
    display: none;
}
.add-special-request {
    text-decoration: underline;
    margin: 0 0 20px 20px;
    display: inline-block;
    cursor: pointer;
    color: var(--main-button-color);
}
.room-price-total {
    margin-top: 10px;
    padding: 10px 20px 50px;
    border-top: 1px solid #eee;
    text-align: right;
    margin: 10px auto;
    max-width: 1200px;
}
#calcPID {
    font-size: 2em;
}
.moveto-payment {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
}
.edit-payment {
    padding-top: 10px;
    display: none;
    justify-content: flex-end;
}
.edit-payment > * {
    margin-left: 10px;
}
.editp-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.editp-container .aDays {
    padding-right: 5px;
}
.editp-container > *:last-child {
    padding-left: 5px;
}
.inner-calcp {
    display: none;
}
.price-editing .roomaddons {
    display: none;
}
.price-editing .edit-payment {
    display: flex;
}
.price-editing .moveto-payment {
    display: none;
}
.final-payment {
    /* display: none; */
    min-height: 100vh;
    /* max-height: 100vh; */
    padding-top: 60px;
}
.back-to-price-edit a {
    display: flex;
    align-items: center;
    font-size: 0.8em;
    line-height: 1;
    cursor: pointer;
}
@media screen and (min-width: 1500px) {
    .avilable-rooms li {
        width: calc(25% - 20px);
    }
}
@media screen and (max-width: 1200px) {
    .avilable-rooms li {
        width: calc(50% - 20px);
    }
}

@media screen and (max-width: 1024px) {
    .image-holder {
        max-height: 100px;
    }
    .avilable-rooms em {
        display: none;
    }
    .select-rooms {
        flex-direction: column;
        overflow: visible;
        max-height: 100%;
    }
    .select-rooms > * {
        width: 100%;
    }
    .reservation-summery {
        max-height: calc(100vh - 375px);
        border-top: 2px solid #ddd;
    }
    .avilable-rooms {
        flex-wrap: nowrap;
        overflow: auto;
        padding: 0 10px;
    }
    .avilable-rooms li {
        width: 33.3vw;
        flex-shrink: 0;
        margin: 10px 10px 10px 0;
    }
    .room-disc p {
        display: none;
    }
    .roomlist-header {
        padding: 10px 10px 0;
    }
    .room-disc {
        padding: 5px 0;
        font-size: 14px;
    }
    .room-disc h3 {
        font-size: 14px;
    }
    .roomlist-header a.bu {
        padding: 5px;
    }
}
@media screen and (max-width: 800px) {
    #stayheader {
        padding: 10px;
    }
    .avilable-rooms li {
        width: 60vw;
        flex-shrink: 0;
    }

    .room-status {
        padding: 0 10px;
    }

    .avilable-rooms {
        padding: 0 10px;
    }
    .reservation-summery h2 {
        padding: 10px 10px 0;
    }
    #summeryRooms dl {
        margin: 10px;
    }
    .rooms-list {
        background: #f5f5f5;
    }
}

.booking-form {
    max-width: 440px;
    margin: 0 0;

    padding: 20px;
}
.booking-form h2 {
    font-size: 1.5em;
    font-weight: 600;
    padding-bottom: 10px;
}
.booking-form em {
    display: block;
    font-size: 0.8em;
    padding-bottom: 10px;
    color: #666;
}
.Guest-full-details {
    padding: 20px;
}
.Guestroom {
    padding: 10px 0;
}
.roomBoxHeading {
    padding-bottom: 10px;
}
.edit-room-travel {
    font-size: 0.85em;
    text-decoration: underline;
    color: var(--main-button-color);
    cursor: pointer;
}
.edit-room-travel:hover {
    text-decoration: none;
}
.Guestroom h3 {
    padding: 20px 0 0;
    font-size: 2em;
    color: var(--main-button-color);
}
.room-guest {
    padding: 20px;
    background: #f5f5f5;
    border: 1px solid #ddd;
    margin-bottom: 20px;
    max-width: 455px;
    position: relative;
}
.room-guest::before {
    content: " ";
    position: absolute;
    left: -4px;
    top: -1px;
    width: 10px;
    height: 75px;
    background: var(--main-button-color);
}
.room-guest .form-field {
    max-width: 430px;
}

.add-travel-plan {
    font-size: 0.85em;
    text-decoration: underline;
    color: var(--main-button-color);
    cursor: pointer;
}
.add-travel-plan:hover {
    text-decoration: none;
}
.transportation ul {
    margin: 0 0 0 10px;
    padding: 0;
    list-style: none;
    border-left: 1px dashed var(--main-button-color);
}
.transportation ul li {
    margin: 5px 0;
    padding: 0 0 0 10px;
    position: relative;
}

.transportation ul li select,
.transportation ul li input {
    font-size: 14px;
    background: none;
    border: none;
    color: #222;
    padding: 5px 0;

    width: 100%;
}
.transportation ul li input {
    padding-left: 5px;
}
.transportation ul li select option,
.transportation ul li select optgroup {
    color: #000;
}
.land-transport .icon {
    background: url("../../img/icons/point.svg") no-repeat center center;
    background-size: 24px;
    position: absolute;
    width: 22px;
    height: 22px;
    left: -12px;
    top: 12px;
}
.land-transport .t-icon {
    background: url("../../img/icons/car.svg") no-repeat center center;
    width: 50px;
    height: 22px;
    background-size: 24px;
}
.boattransport .icon {
    background: url("../../img/icons/point.svg") no-repeat center center;
    background-size: 22px;
    position: absolute;
    width: 22px;
    height: 22px;
    left: -12px;
    top: 14px;
}
.boattransport .t-icon {
    background: url("../../img/icons/boat.svg") no-repeat center center;
    width: 50px;
    height: 22px;
    background-size: 24px;
}
.resort .icon {
    background: url("../../img/icons/resort.svg") no-repeat center center;
    background-size: 22px;
    position: absolute;
    width: 22px;
    height: 22px;
    left: -13px;
    top: 0px;
}
.addpoint,
.addBoatpoint {
    font-size: 13px;
    height: 22px;
    margin: 20px 0;
}
.addpoint a,
.addBoatpoint a {
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: var(--main-button-color);
    font-size: 11px;
}

.addpoint a:hover {
    text-decoration: none;
}
.travel-plan p {
    margin: 0;
    padding: 0 0 10px;
    font-size: 0.85em;
    line-height: 1.5em;
}
.transport-place-time {
    display: flex;
}
.transport-place-time select {
    width: 100%;
}

.transport-place-time > *:first-child {
    padding-right: 10px;
}
.transport-place-time > *.pTime {
    width: 150px;
    background: none;
}
.pTime {
    position: relative;
}
.ptimeLabel {
    font-size: 11px;
    padding: 0 5px;
    color: #999;
    white-space: nowrap;
}
.transport-place-time > *.pPerson {
    width: 100px;
    background: none;
}
@media screen and (max-width: 900px) {
    .final-booking-details {
        display: block;
    }
    .Guest-full-details {
        padding-top: 0;
    }
    .transportation ul li {
        padding-right: 0;
    }
}

.jetty-pickup {
    display: none;
}

.lastpoint {
    padding: 5px 0 0 10px;
    font-size: 14px;
}
.transport-place-time span.trip-Price {
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 14px;
    padding-top: 2px;
    padding-right: 10px;
    position: relative;
}
.transport-place-time span.trip-Price::after {
    content: " ";
    right: 0;
    bottom: -1px;
    background: #222;
    height: 1px;
    width: 100%;
    position: absolute;
}
.transport-place-time span.trip-Price .tprice {
    display: none;
}
.transport-place-time span.trip-Price .bprice {
    display: none;
}
.cloned .transport-place-time span.trip-Price .tprice {
    display: inline-block;
}

.travel-plan {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px 0 10px;
}
.select-travelplan {
    max-width: 1200px;
    margin: 20px auto;
    padding: 0 15px;
}
.addOns-Box{
    margin: 0 0px 0px 0;
    padding: 10px 10px 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* width: calc(25% - 20px); */
    border-radius: 5px;
    background: #fff;
    position: relative;
    min-height: 100px;
    display: flex;
    flex-direction: column;
}
.select-travelplan h2 {
    font-size: 1.8em;
    padding: 0 0 10px;
}
.default-travel-plan {
    padding: 5px 20px 0;
}
.default-travel-plan p {
    font-size: 0.85em;
}
.add-another-boat-group {
    margin-top: -10px;
}
.add-another-boat-group a {
    font-size: 0.85em;
    color: var(--main-button-color);
    text-decoration: underline;
    cursor: pointer;
}
.add-another-boat-group a:hover {
    text-decoration: none;
}
.clonedForm label {
    display: none;
}
.trip {
    padding-bottom: 30px;
}
.trip .table {
    margin-bottom: 10px;
}
.AddlandTrips,
.AddBoatTrips {
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 2px;
    /* display: flex; */
}
.AddlandTrips:hover,
.AddBoatTrips:hover {
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302), 0 2px 6px 2px rgba(60, 64, 67, 0.149);
}
.lTrip {
    padding: 20px;
}
.triprice {
    font-weight: 600;
}
.lTrip .form {
    display: flex;
    justify-content: space-between;
}
.lTrip .form .form-field {
    width: calc(25% - 10px);
}
.landTripTable {
    display: none;
}
.landTripTable.on {
    display: table;
}
.land-trip-row a,
.boat-trip-row a,
.table-freetrip a {
    color: var(--main-button-color);
    text-decoration: underline;
    cursor: pointer;
}

.showBorder{
border-top: 5px solid rgba(0,0,0,0.10);
}

.booking-for-me input {
    width: auto;
    margin: 0 5px 0 0;
}
.booking-for-me label {
    display: flex;
    align-items: center;
    font-weight: normal;
}
.room-guest .last-field {
    padding-top: 10px;
    padding-bottom: 0;
}
.travelpopupcontrainer {
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    align-items: center;
    justify-content: center;
    z-index: 1001;
    padding: 0 10px;
    display: none;
}
.travelpopupcontrainer.on {
    display: flex;
}
.travelpopup {
    max-width: 1000px;
    min-width: 90vw;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    overflow: auto;
    max-height: calc(100vh - 20px);
}
.travel-info {
    padding: 10px 20px;
    font-size: 0.8em;
    background: #f0f6ff;
    border-radius: 10px;
    margin-bottom: 10px;
}
.travel-info ul {
    margin: 0 0 0px;
}
.transport-place-time {
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.transport-place-time > span {
    padding-right: 10px;
}

.transport-place-time > *.pPoint-selections {
    width: calc(100% - 200px);
}
.travel-info p {
    padding: 0 0 0px;
    margin: 0;
}
.travel-button {
    display: flex;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    background: rgba(100%, 100%, 100%, 0.5);
    padding-top: 10px;
}

.arrival.transportation {
    margin-bottom: 20px;
}
.addpoint a {
    position: relative;
    display: inline-flex;
    line-height: 20px;
    padding-left: 5px;
}
.addpoint a span {
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid var(--main-button-color);
    background: #fff;
    color: var(--main-button-color);
    display: flex;
    align-items: center;
    justify-content: center;
    left: -22px;
    font-size: 20px;
}

@media screen and (min-width: 1000px) {
    .arrival-departure {
        display: flex;
        justify-content: space-between;
    }
    .arrival-departure > * {
        width: calc(50% - 10px);
    }
}
.transportation h3 {
    padding-bottom: 20px;
    padding-top: 10px;
}
.departure .boattransport .icon {
    background: url("../../img/icons/resort.svg") no-repeat center center;
}
.departure .static-no-selection .icon {
    background: url("../../img/icons/point.svg") no-repeat center center;
    top: 0;
}
.departure .static-point {
    display: flex;
}
.transportation ul li.static-no-selection {
    margin-bottom: 20px;
}
.static-no-selection.boattransport .t-icon {
    width: 40px;
}
.static-point .lastpoint {
    padding-left: 5px;
}
.guest-detail-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
}
.guest-detail-container .room-guest {
    margin-right: 20px;
    width: 100%;
    min-width: 400px;
}
@media screen and (min-width: 1400px) {
    .guest-detail-container .room-guest {
        max-width: calc(25% - 20px);
    }
}
@media screen and (max-width: 1400px) {
    .guest-detail-container .room-guest {
        max-width: calc(33.33% - 20px);
    }
}
@media screen and (max-width: 1000px) {
    .guest-detail-container .room-guest {
        max-width: calc(50% - 20px);
    }
}
@media screen and (max-width: 650px) {
    .guest-detail-container .room-guest {
        max-width: 400px;
        min-width: 0;
    }
}
#summeryRooms dd a.remove-person-from-room {
    width: 25px;
    height: 25px;
    text-decoration: none;
    color: #222;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
#summeryRooms dd a.remove-person-from-room:hover {
    background: #eee;
}
.addPersonintoroom span {
    font-size: 12px;
    color: #666;
}
#summeryRooms dd.addPersonintoroom span strong,
.bookedRooms .addPersonintoroom span strong {
    font-weight: 600;
    background: #000;
    color: #fff;
    min-width: 1.25em;
    display: inline-block;
    line-height: 1.25en;
    border-radius: 0.625em;
    text-align: center;
}

.minimum-price dd.addPersonintoroom div > span {
    display: none;
}
.minimum-price dd.addPersonintoroom.on div > span {
    display: inline;
}
#summeryRooms dd.addPersonintoroom {
    display: block;
    padding-left: 0;
}

#summeryRooms dd.addPersonintoroom a,
.addPersonintoroomButtons a {
    display: inline-flex;
    text-decoration: none;
    font-size: 12px;
    margin-top: 10px;
    color: #666;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #f0f0f0;
    cursor: pointer;
}
.bookedRooms .addPersonintoroom {
    font-size: 0.5em;
    display: flex;
    justify-content: space-between;
}
.compare-room {
    font-size: 0.5em;
    font-weight: normal;
    position: relative;
}
.compare-heading a {
    color: #666;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.85em;
}
.compare-heading a:hover {
    text-decoration: none;
}
.compare-room-list {
    position: absolute;

    background: #fff;
    box-shadow: 3px 5px 15px 0px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    left: 0;
    top: 0;
    max-width: 100%;
    display: none;
}
.compare-room-list > * {
    display: flex;
    justify-content: space-between;

    padding: 10px 10px 10px 20px;
    border-bottom: 1px solid #eee;
    align-items: center;
}
.compare-room-list > * > *:first-child {
    padding-right: 20px;
    font-weight: normal;
}
.compare-room-list > div > a {
    padding: 5px;

    font-size: 0.85em;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: #fafafa;
    cursor: pointer;
}
.compare-room-list > div > a span {
    color: #fff;
    background-color: var(--main-button-color);
    padding: 5px;
    margin-left: 10px;
    border-radius: 5px;
}
.on .compare-room-list {
    display: block;
}

.select-bed-type {
    display: flex;
    padding: 5px 0;
}
.select-bed-type .bed-Type {
    width: 50%;
    padding-right: 10px;
}
.select-bed-type .room-number {
    width: 50%;
}
.add-extra-room {
    padding: 20px;
}
.skip-for-now {
    margin: 0 20px 0 auto;
}
@media screen and (max-width: 800px) {
    .skip-for-now {
        margin: 0 5px;
    }
}
.final-amount {
    text-align: right;
}
.final-amount strong {
    font-size: 1.8em;
    display: inline-block;
    padding-right: 5px;
}
.final-amount em {
    display: block;
    font-size: 0.8em;
}
.payment-container {
    padding: 20px 30px 30px 30px;
}

.booking-details {
    margin: 0;
    padding: 0;
    list-style: none;
}

.booking-details li {
    padding-bottom: 20px;
}
.booking-details li h3 {
    font-size: 1.25em;
}
.booking-details em {
    font-style: normal;
    font-size: 0.9em;
}
.price-summery-box dl {
    display: flex;
    padding: 0;
    margin: 0;
    width: 100%;
}

.price-summery-box dt,
.price-summery-box dd {
    display: block;
    margin: 0;
    padding: 5px 0;
    border-top: 1px solid #ddd;
}
.price-summery-box dt {
    padding-right: 5px;
    color: #666;
    white-space: nowrap;
}
.price-summery-box dd {
    width: 100%;
}
.price-summery-box dl ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.price-summery-box dl li {
    display: flex;
    justify-content: space-between;

    font-size: 0.85em;
}
.room-price-box li {
    border-bottom: 1px solid #eee;
    padding: 10px 0;
}
.room-price-box li h4 {
    font-weight: 600;
    font-size: 1em;
    padding-bottom: 2px;
}
.room-price-box .price-per-pax {
    text-align: right;
}
.room-price-box .price-per-pax div {
    padding-bottom: 5px;
}
.room-addons,
.room-taxes {
    padding: 10px 0;
}
.room-addons h4,
.room-taxes h4 {
    font-size: 1em;
}
.room-addons li,
.room-taxes li {
    padding-top: 5px;
}
.Total-price {
    border-top: 1px solid #ddd;
    padding: 10px 0;
}
.Total-price ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.85em;
}
.Total-price li {
    padding-bottom: 5px;
    text-align: right;
}
.grand-total {
    text-align: right;
    font-size: 1.5em;
}
.payment-container {
    display: flex;
    justify-content: space-between;
}
.payment-container > * {
    width: calc(50% - 10px);
}
.payment-modes {
    border-left: 1px solid #ddd;
    padding: 0 0 0 20px;
}
.payment-modes h3 {
    padding-bottom: 10px;
}
.payment-mode-fields {
    margin-top: 20px;
    display: none;
}

.payment-mode-fields.cash {
    display: block;
}

@media screen and (max-width: 900px) {
    .payment-container {
        display: block;
        padding: 80px 20px 20px 20px;
    }
    .payment-container > * {
        width: 100%;
    }
    .payment-modes {
        border: none;
        padding: 20px 0;
    }
}
.inner-box {
    max-width: 500px;
    margin: 0 auto;
}
.payment-button {
    padding-top: 10px;
}

